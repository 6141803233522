var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ChangePassword" },
    [
      _c("p", { staticClass: "title" }, [
        _vm._v(" ¿Quieres cambiar la contraseña? ")
      ]),
      _c(
        "form",
        {
          on: {
            input: function($event) {
              return _vm.validation($event)
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _vm._l(_vm.fields, function(field, i) {
            return _c(
              "BaseInput",
              _vm._b(
                {
                  key: i,
                  attrs: { theme: "light" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function() {
                          return [
                            _c("ValidationError", {
                              attrs: { errors: _vm.errors, name: field.name }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: field.value,
                    callback: function($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value"
                  }
                },
                "BaseInput",
                field,
                false
              )
            )
          }),
          _c(
            "BaseButton",
            { staticClass: "submit-button", on: { click: _vm.submit } },
            [_vm._v(" Enviar ")]
          )
        ],
        2
      ),
      _c("font-awesome-icon", {
        staticClass: "icon",
        attrs: { icon: ["fas", "user-lock"] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }