<template>
  <div class="ProfileInformation">
    <h1 class="company">
      {{ user.company }}

      <span>
        {{ `(${user.cif})` }}
      </span>
    </h1>

    <p class="email">
      {{ user.email }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ProfileInformation{
  background: #fff;
  padding: $spacing-xl;
  margin-bottom: $spacing-m;

  .company{
    font-size: $font-size-3xl;
    font-weight: 500;
    color: $color-primary;

    span{
      color: $color-neutral-dark;
      font-size: $font-size-m;
    }
  }

  .email{
    grid-area: email;
    font-style: italic;
    color: $color-neutral-dark;
  }
}
</style>
