var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ProfileInformation" }, [
    _c("h1", { staticClass: "company" }, [
      _vm._v(" " + _vm._s(_vm.user.company) + " "),
      _c("span", [_vm._v(" " + _vm._s("(" + _vm.user.cif + ")") + " ")])
    ]),
    _c("p", { staticClass: "email" }, [
      _vm._v(" " + _vm._s(_vm.user.email) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }