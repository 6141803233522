<template>
  <div class="ChangePassword">
    <p class="title">
      ¿Quieres cambiar la contraseña?
    </p>

    <form
      @input="validation($event)"
      @keyup.enter="submit"
    >
      <BaseInput
        v-for="(field, i) in fields"
        :key="i"
        v-model="field.value"
        v-bind="field"
        theme="light"
      >
        <template #error>
          <ValidationError
            :errors="errors"
            :name="field.name"
          />
        </template>
      </BaseInput>

      <BaseButton
        class="submit-button"
        @click="submit"
      >
        Enviar
      </BaseButton>
    </form>

    <font-awesome-icon
      class="icon"
      :icon="['fas', 'user-lock']"
    />
  </div>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    ValidationError,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      fields: [
        {
          type: 'password',
          name: 'password',
          icon: ['fas', 'key'],
          placeholder: 'Introduce tu contraseña',
          error: 'Introduce una contraseña',
          required: true,
          value: undefined,
          autofocus: true,
        },
        {
          type: 'password',
          name: 'passwordNew',
          icon: ['fas', 'key'],
          placeholder: 'Introduce tu nueva contraseña',
          error: this.$t('FORM.MESSAGE.ERROR.PASSWORD_REGEXP'),
          pattern: '\\S{8,}',
          required: true,
          value: undefined,
        },
        {
          type: 'password',
          name: 'passwordRepeat',
          icon: ['fas', 'key'],
          placeholder: 'Introduce de nuevo tu nueva contraseña',
          error: this.$t('FORM.MESSAGE.ERROR.PASSWORD_REGEXP'),
          pattern: '\\S{8,}',
          required: true,
          value: undefined,
        },
      ],
    };
  },

  methods: {
    async submit() {
      try {
        const currentForm = document.forms[0];
        this.checkAllFields(currentForm);

        const password = this.getField('password');
        const passwordNew = this.getField('passwordNew');
        const passwordRepeat = this.getField('passwordRepeat');

        if (this.areErrors()) {
          throw new Error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
        }

        if (passwordNew !== passwordRepeat) {
          const reppasswordField = currentForm.querySelector('[name=passwordRepeat]');
          this.forceErrorElement(reppasswordField, this.$t('FORM.ERROR.REPEATPASSWORD'));
          throw new Error(this.$t('FORM.MESSAGE.ERROR.PASSWORDSMATCH'));
        }

        await this.send({ password, passwordNew });

        this.clearFields();
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    async send({ password, passwordNew }) {
      await this.$store.dispatch('authModule/replacePassword', { password, passwordNew });
    },

    clearFields() {
      this.fields = this.fields.map(field => ({ ...field, value: undefined }));
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangePassword{
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: $spacing-xs;
  align-content: start;
  padding: $spacing-xl;
  background-color: $color-neutral-lighter;
  position: relative;
  z-index: 1;
  box-shadow: $shadow-elevation-1;

  .title{
    grid-column: 1/3;
    font-size: $font-size-xl;
    font-weight: 500;
    margin-bottom: $spacing-m;
    text-transform: uppercase;
  }

  form .submit-button{
    margin-top: $spacing-l;
    justify-self: start;
  }

  .icon{
    position: absolute;
    font-size: 8rem;
    transform: rotate(-10deg);
    bottom: -1.25rem;
    // bottom: 0;
    right: .5rem;
    color: adjust-color($color-neutral-mid-dark, $alpha: -.6);
  }

  @media #{$breakpoint-lg-max}{
    &{
      grid-template-columns: 1fr;
      .title{
        grid-column: 1;
      }
    }
  }
}
</style>
