<template>
  <div class="ProfileView">
    <HeaderTemplate image="profile-header.jpg" />

    <div class="content fill-content">
      <TransitionSlide side="right">
        <div
          v-if="user"
          class="profile-panel fill-content"
        >
          <ProfileInformation :user="user" />

          <ChangePassword />
        </div>
      </TransitionSlide>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import HeaderTemplate from '@/components/common/HeaderTemplate';
import TransitionSlide from '@/transitions/TransitionSlide.vue';
import ProfileInformation from '@/components/profile/ProfileInformation.vue';
import ChangePassword from '@/components/profile/ChangePassword.vue';

const STORE = 'authModule';

export default {
  components: {
    HeaderTemplate,
    TransitionSlide,
    ProfileInformation,
    ChangePassword,
  },

  computed: {
    user: get(`${STORE}/getUser`),
  },
};
</script>

<style lang="scss" scoped>
.ProfileView{
  .content{
    padding: $spacing-xl;

    .profile-panel{
      border-radius: $border-radius-m;
      overflow: hidden;
    }
  }
}
</style>
