// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/montserrat/Montserrat-Regular.WOFF");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/montserrat/Montserrat-Italic.WOFF");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/montserrat/Montserrat-SemiBold.WOFF");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/fonts/montserrat/Montserrat-SemiBoldItalic.WOFF");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/fonts/montserrat/Montserrat-Bold.WOFF");
var ___CSS_LOADER_URL_IMPORT_5___ = require("@/assets/fonts/montserrat/Montserrat-BoldItalic.WOFF");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "[data-v-53755b99]:export {\n  sm: 576;\n  md: 768;\n  lg: 992;\n  xl: 1300;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n  font-weight: normal;\n  font-style: italic;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");\n  font-weight: 500;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");\n  font-weight: 500;\n  font-style: italic;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\");\n  font-weight: bold;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");\n  font-weight: bold;\n  font-style: italic;\n}\n@-webkit-keyframes shine-data-v-53755b99 {\n0% {\n    background-position: -100%;\n}\n100% {\n    background-position: 100%;\n}\n}\n@keyframes shine-data-v-53755b99 {\n0% {\n    background-position: -100%;\n}\n100% {\n    background-position: 100%;\n}\n}\n.ProfileInformation[data-v-53755b99] {\n  background: #fff;\n  padding: 2rem;\n  margin-bottom: 1rem;\n}\n.ProfileInformation .company[data-v-53755b99] {\n  font-size: 2rem;\n  font-weight: 500;\n  color: #8a3df5;\n}\n.ProfileInformation .company span[data-v-53755b99] {\n  color: #484f56;\n  font-size: 1rem;\n}\n.ProfileInformation .email[data-v-53755b99] {\n  grid-area: email;\n  font-style: italic;\n  color: #484f56;\n}", ""]);
// Exports
module.exports = exports;
