var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProfileView" },
    [
      _c("HeaderTemplate", { attrs: { image: "profile-header.jpg" } }),
      _c(
        "div",
        { staticClass: "content fill-content" },
        [
          _c("TransitionSlide", { attrs: { side: "right" } }, [
            _vm.user
              ? _c(
                  "div",
                  { staticClass: "profile-panel fill-content" },
                  [
                    _c("ProfileInformation", { attrs: { user: _vm.user } }),
                    _c("ChangePassword")
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }